import { User } from './user';

/// The different roles a user can have in an organization.
export enum OrganizationRole {
  /// Owners can manage the organization and its apps, and can add and remove
  /// users. There can only be one owner.
  owner = 'owner',
  /// Admins can do everything owners can do, except transfer ownership and
  /// delete the organization.
  admin = 'admin',
  /// Members can view and manage apps in the organization.
  member = 'member',
}

/// The different types of organizations we support.
export enum OrganizationType {
  /// Personal organizations have a single owner member (the user they are
  /// associated with). They are created automatically when a user signs up.
  personal = 'personal',
  /// Team organizations can have multiple members and only subscribing users
  /// are able to create them.
  team = 'team',
}

/// An organization that owns apps and has users.
export interface Organization {
  /// The identifer of the organization.
  id: number;

  /// The display name of the organization.
  name: string;

  /// Whether this organization is personal or team.
  type: OrganizationType;
}

/// A user's organizations and their role in each.
export interface OrganizationMembership {
  /// The organization the user belongs to.
  organization: Organization;

  /// The user's role in the organization.
  role: OrganizationRole;
}

/// A user within an organization.
export interface OrganizationUser {
  /// The role of the user in the organization.
  role: OrganizationRole;

  /// The user. This will be a subset of the full user object, containing only
  /// non-sensitive information.
  user: User;
}
